<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <Toast />
                <h5>{{ $t('menu.amlReport') }}</h5>
                <div class="p-grid">
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <Calendar ref="startDateCalendar" style="width: 100%" dateFormat="dd.mm.yy" :showTime="true" hourFormat="24" :showIcon="true" :showButtonBar="true" v-model="startDateModel"></Calendar>
                            <label>{{ $t('sportsbets.startDate') }}</label>
                        </span>
                    </div>
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <Calendar style="width: 100%" dateFormat="dd.mm.yy" :showTime="true" hourFormat="24" :showIcon="true" :showButtonBar="true" v-model="endDateModel"></Calendar>
                            <label>{{ $t('sportsbets.endDate') }}</label>
                        </span>
                    </div>
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <InputText type="text" v-model="winValue" />
                            <label>{{ $t('reports.minWinValue') }}</label>
                        </span>
                    </div>
                    <div class="p-col-6 p-md-5 p-text-right"><Button type="button" :loading="isReportLoading" :label="$t('buttons.show')" class="p-button-raised p-button-success" @click="showReport()" /></div>
                    <div class="p-col-6 p-md-1 p-text-right">
                        <Button type="button" :label="$t('buttons.saveXls')" :loading="isReportDownloading" icon="pi pi-download" class="p-button-raised p-button-info" @click="saveXls()" />
                    </div>
                    <transition-group name="p-message" tag="div" class="p-col-12">
                        <Message :key="errorKeyCount" :severity="'error'" v-show="showError">{{ errorText }}</Message>
                    </transition-group>
                </div>
                <div class="card" v-if="showReportData">
                    <DataTable @page="onPage($event)" :paginator="true" :rows="perPage" :loading="loading" class="p-mt-3" ref="datatable" responsiveLayout="scroll" :paginatorBottom="true" :value="reportData">
                        <Column>
                            <template #body="slotProps">
                                <span class="indexColumn">{{ (this.crtPage - 1) * this.perPage + slotProps.index + 1 }}</span>
                            </template>
                        </Column>
                        <Column field="ticketSerial" :header="$t('bets.ticketSerial')" :sortable="true"></Column>
                        <Column field="ticketDate" :header="$t('bets.placeDate')" :sortable="true">
                            <template #body="{ data }"> {{ formatCSDateAndTime(data.ticketDate) }} </template>
                        </Column>
                        <Column field="addShop" :header="$t('bets.shopName')" :sortable="true"> </Column>
                        <Column field="addUser" :header="$t('bets.cashier')" :sortable="true"> </Column>
                        <Column field="payDate" :header="$t('bets.payDate')" :sortable="true">
                            <template #body="{ data }"> {{ formatCSDateAndTime(data.payDate) }} </template>
                        </Column>
                        <Column field="payShop" :header="$t('bets.payShop')" :sortable="true"></Column>
                        <Column field="payUser" :header="$t('bets.paidBy')" :sortable="true"></Column>
                        <Column field="winAmount" :header="$t('bets.winAmount')" :sortable="true">
                            <template #body="{ data }"> {{ formatCurrency(data.winAmount) }} </template>
                        </Column>
                        <Column field="winTaxAmount" :header="$t('bets.winTaxAmount')" :sortable="true">
                            <template #body="{ data }"> {{ formatCurrency(data.winTaxAmount) }} </template>
                        </Column>
                        <Column field="cnp" :header="$t('bets.winnerId')" :sortable="true"> </Column>
                    </DataTable>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { BoReportsApi } from '../../../service/BoReportsApi';
import { BoSaveReportsApi } from '../../../service/BoSaveReportsApi';
import { uiSettings } from '../../../settings/uiSettings';

export default {
    name: 'report',
    data() {
        return {
            stats: {},
            BoReportsApi: new BoReportsApi(),
            BoSaveReportsApi: new BoSaveReportsApi(),
            isReportLoading: false,
            isReportDownloading: false,
            showReportData: false,
            startDateModel: null,
            endDateModel: null,
            errorKeyCount: 0,
            showError: false,
            winValue: 0,
            minWinValue: 9000,
            errorText: '',
            perPage: uiSettings.tableRows,
            crtPage: 1,
        };
    },
    mounted() {
        let crtDate = new Date();
        let year = crtDate.getFullYear();
        let month = crtDate.getMonth() + 1;
        if (month <= 9) {
            month = '0' + month;
        }
        let day = crtDate.getDate();
        if (day <= 9) {
            day = '0' + day;
        }
        var startDateString = year + '-' + month + '-' + day + 'T00:00';
        var endDateString = year + '-' + month + '-' + day + 'T23:59';
        this.startDateModel = new Date(startDateString);
        this.endDateModel = new Date(endDateString);
        this.winValue = this.minWinValue;
    },
    methods: {
        showReport() {
            this.showReportData = false;
            if (this.winValue < this.minWinValue) {
                this.showToast('error', this.$t('reports.alerts.minWinAmount', { amount: this.minWinValue }));
            } else {
                this.isReportLoading = true;
                this.BoReportsApi.getAmlReport(this.formatEnDateAndTime(this.startDateModel), this.formatEnDateAndTime(this.endDateModel), this.winValue)
                    .then((response) => {
                        this.reportData = response.data;
                        this.isReportLoading = false;
                        this.showReportData = true;
                    })
                    .catch((error) => {
                        this.checkForUnauthorizedError(error);
                        this.showError = true;
                        this.errorKeyCount++;
                        this.isReportLoading = false;
                    });
            }
        },
        saveXls() {
            if (this.winValue < this.minWinValue) {
                this.showToast('error', this.$t('reports.alerts.minWinAmount', { amount: this.minWinValue }));
            } else {
                this.isReportDownloading = true;
                this.BoSaveReportsApi.saveAmlReport(this.formatEnDateAndTime(this.startDateModel), this.formatEnDateAndTime(this.endDateModel), this.winValue)
                    .then((response) => {
                        this.isReportDownloading = false;
                        let blob = new Blob([response.data], { type: 'application/xls' });
                        let link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = 'AMLReport_' + this.formatEnDateAndTime(this.startDateModel) + '_' + this.formatEnDateAndTime(this.endDateModel) + '.xlsx';
                        link.click();
                    })
                    .catch((error) => {
                        this.checkForUnauthorizedError(error);
                        this.showError = true;
                        this.errorKeyCount++;
                        this.isReportDownloading = false;
                    });
            }
        },
        debounceSearch(event) {
            clearTimeout(this.debounce);
            this.debounce = setTimeout(() => {
                this.term = event.target.value;
            }, this.debounceTime);
        },
        onPage(event) {
            this.crtPage = event.page + 1;
        },
        showToast(toastSeverity, message) {
            this.$toast.add({ severity: toastSeverity, summary: '', detail: message, life: this.getToastLifeSeconds() });
        },
    },
};
</script>